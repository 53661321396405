import React, { FunctionComponent } from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Layout } from "../templates/Layout";
import { Grid, Box } from "@material-ui/core";
import { ContentsCard } from "../components/contentsCards/ContentsCard";
import { BreadNav } from "../components/common/BreadNav";
import { Seo } from "../components/helmets/Seo";

const Posts : FunctionComponent = () =>{

  const renderMarkdownRemark = (markdownRemark: any) => (
    <Grid item key={markdownRemark.id}>
      <ContentsCard
        title={markdownRemark.frontmatter.title}
        description={markdownRemark.frontmatter.description || markdownRemark.excerpt}
        imageFluid={markdownRemark.frontmatter.cover?.childImageSharp.fluid}
        link={`/posts${markdownRemark.fields.slug}`}
        tag={markdownRemark.frontmatter.maintag}
        date={markdownRemark.frontmatter.date}
      />
    </Grid>
  );

  const data = useStaticQuery(
    graphql`
      query AllPosts{
        allFile(
          filter: {
            extension: {eq: "md"}, 
            absolutePath: {regex: "/\\/posts\\//"}
            childMarkdownRemark: {frontmatter: {publish: {eq: "true"}}}
          },
          sort: {
            fields: childMarkdownRemark___frontmatter___date,
            order: DESC
          }
        )
        {
          edges {
            node {
              childMarkdownRemark {
                id
                excerpt(pruneLength: 160)
                frontmatter {
                  title
                  cover {
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  date(formatString: "YYYY/MM/DD")
                  description
                  maintag
                }
                fields {
                  slug
                }
              }
              absolutePath
            }
          }
        }
      }
    `
  );

  const allPosts = data.allFile.edges
    .filter(edge => edge.node.absolutePath.includes("/posts/"))
    .map(edge => edge.node.childMarkdownRemark);

  return (
    <Layout>
      <Seo title="Posts" />
      <Box my={2}>
        <BreadNav currentPage="Posts" links={[{label: "Home", link: "/"}]} />
      </Box>
      <Grid container direction="row" spacing={4}>
        {allPosts.map(post => renderMarkdownRemark(post))}
      </Grid>
    </Layout>
  );
}

export default Posts;